/*
 * This is a renderless Component that GETS Announcements for the landing page. If is currently only called by
 * <LandingPageAnnouncements />
 */
import { useEffect } from 'react'
import axios from 'axios'
import { get_announcements } from '../Helpers'
import PropTypes from 'prop-types'

const GetAnnountementsData = ({getData}) => {

  /* GET Announcements for the landing page
     res.data is { msg: 'get_announcements.php Is Up Man!', allAnnouncements: [ { MySQL data objects } ] } */
  useEffect( () => {
         axios.get(get_announcements)
         .then( res => {
            getData(res.data)
         } )
  }, [getData] )

  return null

}

GetAnnountementsData.propTypes = {
   getData: PropTypes.func,
 }

export default GetAnnountementsData
