import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core'
import { get_notable_alumni } from '../Helpers'

const LandingPageNotableAlumni = props => {

   const [ allGrads, setAllGrads ] = useState([])

   useEffect(() => {
      /* Set axios headers back to what they need to be for a PHP call. This prevents a cors error that was happening when
         <LandingPageCenterSection /> was making a Node GET with a different axios.defaults.headers.common. */
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
      axios.get(get_notable_alumni)
          .then(res => res.data)
          .then(data => {
              setAllGrads(data.allGrads)
              return data
          })
  }, [] )

   const displayGrads = allGrads.map(grad => {
      const { aceUserId, givenFirstName, gradNumber, lastName, medalOfHonorUserId, militaryRank, nahfInducteeYear, nahf_url, nahfUserId, notableAlumniUserId,
          notableAlumniAchievement1, notableAlumniAchievement2, notableAlumniAchievement3, notableAlumniAchievement4, notableAlumniAchievement5, urlText } = grad

      return (
          <div key={notableAlumniUserId} style={{ marginBottom: "1.5rem" }} >
              <Typography variant="h6" style={{ textAlign: "left", marginLeft: "1rem" }}>
                  {militaryRank} {givenFirstName} {lastName} (Alumni #{gradNumber})
                  </Typography>
              {aceUserId !== null ? <Typography style={{ textAlign: "right", marginRight: "1rem" }}>Ace</Typography> : null}
              {medalOfHonorUserId !== null ? <Typography style={{ textAlign: "right", marginRight: "1rem" }}>Medal of Honor</Typography> : null}
              {notableAlumniAchievement1 !== null ? <Typography style={{ textAlign: "right", marginRight: "1rem" }}> {notableAlumniAchievement1} </Typography> : null}
              {notableAlumniAchievement2 !== null ? <Typography style={{ textAlign: "right", marginRight: "1rem" }}> {notableAlumniAchievement2} </Typography> : null}
              {notableAlumniAchievement3 !== null ? <Typography style={{ textAlign: "right", marginRight: "1rem" }}> {notableAlumniAchievement3} </Typography> : null}
              {notableAlumniAchievement4 !== null ? <Typography style={{ textAlign: "right", marginRight: "1rem" }}> {notableAlumniAchievement4} </Typography> : null}
              {notableAlumniAchievement5 !== null ? <Typography style={{ textAlign: "right", marginRight: "1rem" }}> {notableAlumniAchievement5} </Typography> : null}
              {nahfUserId !== null && nahfInducteeYear !== null ? <Typography style={{ textAlign: "right", marginRight: "1rem" }}>
                  <a href={nahf_url} target="_blank" rel="noreferrer noopener" style={{ color: "inherit" }}>{nahfInducteeYear} - {urlText}</a>
              </Typography>
              :
                  <Typography style={{ textAlign: "right", marginRight: "1rem" }}>
                      <a href={nahf_url} target="_blank" rel="noreferrer noopener" style={{ color: "inherit" }}>{urlText}</a>
                  </Typography>
              }
          </div>
      )
  })

   return (
      <Grid className='landing-notable-alumni-main-div' item sm={12} md={4} >
         <Paper style={{ backgroundColor: "rgba(0, 0, 255, 0.05)" }}>
            <Typography variant="h5" style={{ paddingTop: "1rem", paddingBottom: "1rem" }} className="notableAlumniHeader">
               Notable Alumni
               </Typography>
            <Box style={{ width: "100%", paddingBottom: "2rem" }}>
               <Divider light />
            </Box>
            <div className="notableAlumni">
               {displayGrads}
            </div>
         </Paper>
      </Grid>
   )
}

export default LandingPageNotableAlumni
