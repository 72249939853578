import React, { useState } from 'react'
import { allGradsAction } from "../Redux/Actions"
import axios from 'axios'
import Button from "@material-ui/core/Button"
import { get_grads_by_city_state, get_grads_by_company, get_grads_by_current_job_title, get_grads_by_school, getGradsByTpsClass } from '../Helpers'
import Grid from '@material-ui/core/Grid'
import { Link } from "react-router-dom"
import MySelect from './MySelect'
import PropTypes from 'prop-types'
import { store } from "../Redux/Store"

const SearchBoxTwo = ({ data, type }) => {

    const [ showSubmitButton, setShowSubmitButton ] = useState(false)

    // This is the second dropdown where the user selects the city or state in which to search
    //  for other alumni
    const handleChange = (e) => {

        var city = ''
        var state = ''

        // Search the db for e.target.value from the Select dropdown
        // It will either be:
        // city, state
        // city, (No State Listed)
        // state

        // This is when a city is listed in the db, but the state was left blank
        // This will search only for city
        if (e.target.value.includes('(No State Listed)')) {
            city = e.target.value.slice(0, e.target.value.indexOf(','))
        }
        // This is for city, state
        else if (e.target.value.includes(', ')) {
            city = e.target.value.slice(0, e.target.value.indexOf(','))
            state = e.target.value.slice(e.target.value.length - 2, e.target.value.length)
        }
        // This is the state section
        else if (e.target.value.length === 2) {
            state = e.target.value
        }

        var searchByCityStateData = new FormData()

        searchByCityStateData.append("city", city)
        searchByCityStateData.append("state", state)

        axios.post(get_grads_by_city_state, searchByCityStateData)
            .then(res => res.data)
            .then(data => {
                store.dispatch(allGradsAction(data.allGrads))
                setShowSubmitButton(true)
                return data
            })
    }

    /* This is the second dropdown where the user selects the TPS Class in which to search
     * for other alumni.
     * data.allGrads is an array of objects like: [ {userId: 2368}, {userId: 12} ] */
    const handleGetGradsByTPSClass = (e) => {

        getGradsByTpsClass(e.target.value)
        setShowSubmitButton(true)
    }

    /* This is the second dropdown where the user selects the Current Job Title in which to search
     * for other alumni.
     * data.allGrads is an array of objects like: [ {userId: 2368}, {userId: 12} ] */
    const handleGetGradsByCurrentJobTitle = (e) => {

        const currentJobTitle = e.target.value

        var searchByCurrentJobTitle = new FormData()

        searchByCurrentJobTitle.append("currentJobTitle", currentJobTitle)

        axios.post(get_grads_by_current_job_title, searchByCurrentJobTitle)
            .then(res => res.data)
            .then(data => {
                store.dispatch(allGradsAction(data.allGrads))
                setShowSubmitButton(true)
                return data
            })
    }

    /* This is the second dropdown where the user selects the Company in which to search
     * for other alumni.
     * data.allGrads is an array of objects like: [ {userId: 2368}, {userId: 12} ] */
    const handleGetGradsByCompany = (e) => {

        const currentCompany = e.target.value

        var searchByCompany = new FormData()

        searchByCompany.append("currentCompany", currentCompany)

        axios.post(get_grads_by_company, searchByCompany)
            .then(res => res.data)
            .then(data => {
                store.dispatch(allGradsAction(data.allGrads))
                setShowSubmitButton(true)
                return data
            })
    }

    /* This is the second dropdown where the user selects the School in which to search
     * for other alumni.
     * data.allGrads is an array of objects like: [ {userId: 2368}, {userId: 12} ] */
    const handleGetGradsByEducation = (e) => {

        const school = e.target.value

        var searchBySchool = new FormData()

        searchBySchool.append("school", school)

        axios.post(get_grads_by_school, searchBySchool)
            .then(res => res.data)
            .then(data => {
                store.dispatch(allGradsAction(data.allGrads))
                setShowSubmitButton(true)
                return data
            })
    }

    return (
        <Grid container >
            {(type === 'city' || type === 'state') ?
                <Grid container justify='center' item style={{ width: "70%" }} >
                    <MySelect name="searchSelection" label="Select" options={data} onChange={handleChange} />
                </Grid>
                : null}

            {/* If Search by TPS Class is selected this Pops up Dropdown #2 for a list of classes with registered users */}
            {type === 'class' ?
                <Grid container justify='center' item style={{ width: "70%" }} >
                    <MySelect name="searchSelection" label="Select" options={data} onChange={handleGetGradsByTPSClass} />
                </Grid>
                : null}

            {/* If Search by Current Job Title is selected this Pops up Dropdown #2 for a list of Current Job Titles with registered users */}
            {type === 'currentJobTitle' ?
                <Grid container justify='center' item style={{ width: "70%" }} >
                    <MySelect name="searchSelection" label="Select" options={data} onChange={handleGetGradsByCurrentJobTitle} />
                </Grid>
                : null}

            {/* If Search by Company is selected this Pops up Dropdown #2 for a list of Company with registered users */}
            {type === 'currentCompany' ?
                <Grid container justify='center' item style={{ width: "70%" }} >
                    <MySelect name="searchSelection" label="Select" options={data}  onChange={handleGetGradsByCompany} />
                </Grid>
                : null}

            {/* If Search by Education is selected this Pops up Dropdown #2 for a list of Schools with registered users */}
            {type === 'education' ?
                <Grid container justify='center' item style={{ width: "70%" }} >
                    <MySelect name="searchSelection" label="Select" options={data}  onChange={handleGetGradsByEducation} />
                </Grid>
                : null}

            {( type === 'city' || type === 'state' || type === 'class' || type === 'currentJobTitle' || type === 'currentCompany' || type === 'education' ) && showSubmitButton ?
                <Grid item md={12}>
                    <Link to="/GetGradsByUserId" style={{ textDecoration: "none" }}>
                        <Button variant="contained" color="primary" >
                            Submit
                        </Button>
                    </Link>
                </Grid>
                : null}
        </Grid>
    )
}

SearchBoxTwo.propTypes = {
    data: PropTypes.array,
    type: PropTypes.string
}

export default SearchBoxTwo
