import React, { useEffect, useState } from 'react'
import AnalyticBadRegisters from '../Components/AnalyticBadRegisters'
import ShowNewRegisters from '../Components/ShowNewRegisters'
import axios from 'axios'
import { Box, Button, Container, Grid, Typography } from '@material-ui/core'
import { CLIENT_PUBLIC_TOKEN, get_basic_analytics, get_grads_by_user_id_from_tbl_auth, node_server, three_month_lookback } from '../Helpers'

const Analytics = () => {

   const [ totalFailedLogins, setTotalFailedLogins ] = useState(0)
   const [ totalNumberOfBios, setTotalNumberOfBios ] = useState(0)
   const [ totalNumberOfGrads, setTotalNumberOfGrads ] = useState(0)
   const [ totalNumberOfRegisteredGrads, setTotalNumberOfRegisteredGrads ] = useState(0)

   /* There are used to determine who has an active account, but no data in tbl_user indicating
      either there is an error in the site, or they logged in and then either logged out or just
      closed their brower window without entering any data */
   const [ badUserIds, setBadUserIds ] = useState([])
   const [ badUserInfo, setBadUserInfo ] = useState([])
   const [ calcBadRegister, setCalcBadRegiser ] = useState(false)
   const [ inTblUserUserIds, setInTblUserUserIds ] = useState([])
   const [ isActiveUserIds, setIsActiveUserIds ] = useState([])
   const [ showAnalyticBadRegisters, setShowAnalyticBadRegisters ] = useState(false)

   /* Total number of grads who have deleted their data from the site using <GradCard /> */
   const [ totalNumberOfDeletedGrads, setTotalNumberOfDeletedGrads ] = useState(0)

   /* 30, 60, 90 day lookback numbers wrt new registrations */
   const [ thirtyDayCount, setThirtyDayCount ] = useState(0)
   const [ sixtyDayCount, setSixtyDayCount ] = useState(0)
   const [ ninetyDayCount, setNinetyDayCount ] = useState(0)
   const [ thirtyDayArray, setThirtyDayArray ] = useState([])
   const [ sixtyDayArray, setSixtyDayArray ] = useState([])
   const [ ninetyDayArray, setNinetyDayArray ] = useState([])

   /* Booleans to control the showing of 30, 60, 90 day registrations */
   const [ show30Button, setShow30Button ] = useState(true)
   const [ show60Button, setShow60Button ] = useState(true)
   const [ show90Button, setShow90Button ] = useState(true)
   const [ show30Names, setShow30Names ] = useState(true)
   const [ show60Names, setShow60Names ] = useState(true)
   const [ show90Names, setShow90Names ] = useState(true)

   /* Get data from MySQL database
      Total Number of Grads
      Total Number of Registered Grads */
   useEffect( () => {
      axios.get(get_basic_analytics)
         .then( res => {
            const { data } = res
            setTotalNumberOfGrads(data.totalNumberOfGrads)
            setTotalNumberOfRegisteredGrads(data.totalNumberOfRegisteredGrads)
            setTotalFailedLogins(data.numTotalFailedLogins)
            setInTblUserUserIds(data.inTblUserUserIds)
            setIsActiveUserIds(data.isActiveUserIds)
            setCalcBadRegiser(true)
         } )
   }, [] )

   /* Get data from MySQL database
      Number of new grads in the last 30, 60, 90 days */
   useEffect( () => {
      axios.get(three_month_lookback)
         .then( res => {
            const { data } = res

            /* Get time now and subtract 90 days worth of milliseconds */
            const ninetyDayLookback = new Date(Date.now() - (90 * 24 * 60 * 60 * 1000))
            const myDay = ninetyDayLookback.getDate()
            /* Adjust month since it is zero indexed */
            const myMonth = ninetyDayLookback.getMonth() + 1
            const myYear = ninetyDayLookback.getFullYear()

            /* Form 30, 60, 90 days strings
               If month is a single-digit, add a 0 to the front */
            const thirtyDayString = myMonth.toString.length === 1 ? `${myYear}-0${myMonth + 2}-${myDay}` : `${myYear}-${myMonth + 2}-${myDay}`
            const sixtyDayString = myMonth.toString.length === 1 ? `${myYear}-0${myMonth + 1}-${myDay}` : `${myYear}-${myMonth + 1}-${myDay}`
            const ninetyDayString = myMonth.toString.length === 1 ? `${myYear}-0${myMonth}-${myDay}` : `${myYear}-${myMonth}-${myDay}`
               
            if ( data.allGrads ) {
               let tempThirtyDayCount = 0
               let tempSixtyDayCount = 0
               let tempNinetyDayCount = 0
               const tempThirtyDayArray = []
               const tempSixtyDayArray = []
               const tempNinetyDayArray = []

               data.allGrads.map( g => {
                  if (g.activatedDate > thirtyDayString) { tempThirtyDayCount++; tempThirtyDayArray.push(g) }
                  if (g.activatedDate > sixtyDayString) { tempSixtyDayCount++; tempSixtyDayArray.push(g) }
                  if (g.activatedDate > ninetyDayString) { tempNinetyDayCount++; tempNinetyDayArray.push(g) }
                  return null
               })

               setThirtyDayCount(tempThirtyDayCount)
               setSixtyDayCount(tempSixtyDayCount)
               setNinetyDayCount(tempNinetyDayCount)
               setThirtyDayArray(tempThirtyDayArray)
               setSixtyDayArray(tempSixtyDayArray)
               setNinetyDayArray(tempNinetyDayArray)
            }
         } )
   }, [] )

   /* Get data from MongoDB database
      Total number of Bios */
   useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + 'bio/GetTotalNumberOfBios')
         .then( res => {
            const { data } = res
            setTotalNumberOfBios(data.totalNumberOfBios)

            /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
            after a GET was made to the Node server. */
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         } )
   }, [] )

   /* Which users are active but not in tbl_user? 
   *
   * This takes advantage of objects rather than using a nested FOR loop which would be
   * Big O(N ^ 2), this method is Big O(N) which is a lot faster and more scalable
   */
   useEffect( () => {
      if (calcBadRegister) {
         let inTblUserObj = {}
         let isActiveObj = {}

         for (const i of inTblUserUserIds) {
            inTblUserObj[i.userId] = i.userId
         }

         for (const i of isActiveUserIds) {
            isActiveObj[i.userId] = i.userId
         }

         for (const isActive of isActiveUserIds) {
            if (inTblUserObj[isActive.userId] === undefined) {
               let tempIds = badUserIds
               tempIds.push(isActive.userId)
               setBadUserIds(tempIds)
            }
         }
         setShowAnalyticBadRegisters(true)
      }
   }, [badUserIds, calcBadRegister, inTblUserUserIds, isActiveUserIds] )

   /*  */
   useEffect( () => {

      if (showAnalyticBadRegisters) {
         let formData = new FormData()

         formData.append('allGrads', badUserIds)

         /* Set axios headers back to what they need to be for a PHP call. This prevents a cors error that was happening when
            <Analytics /> was making a Node GET with a different axios.defaults.headers.common. */
         axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         axios.post(get_grads_by_user_id_from_tbl_auth, formData)
            .then( res => {
               setBadUserInfo(res.data.allGrads)
            } )
      }

   }, [badUserIds, showAnalyticBadRegisters] )

      /* Get all grads from MongoDB where the user deleted their info via <GradCard /> */
      useEffect( () => {
         axios.get(node_server + 'deletedGrad')
            .then( res => {
                  setTotalNumberOfDeletedGrads(res.data.allDeletedGrads.length)
            })
      }, [] )

      const showThirty = () => {
         setShow30Names(!show30Names)
         setShow60Button(!show60Button)
         setShow90Button(!show90Button)
      }

      const showSixty = () => {
         setShow30Button(!show30Button)
         setShow60Names(!show60Names)
         setShow90Button(!show90Button)
      }

      const showNinety = () => {
         setShow30Button(!show30Button)
         setShow60Button(!show60Button)
         setShow90Names(!show90Names)
      }

   return (
      <Box>
         <Container>
            <Grid style={{marginTop: '5rem'}}>
               <Typography variant='h3'>
                  Analytics
               </Typography>
               <Grid container style={{marginTop: '2rem'}}>

                  <Grid container item xs={12} sm={6} justify='flex-start'>
                     <Typography variant='h5'>
                        Total number of grads: {totalNumberOfGrads}
                     </Typography>
                     <Typography variant='h5'style={{marginTop: '0.5rem'}}>
                        Total number of Registered grads: {totalNumberOfRegisteredGrads} ( {(totalNumberOfRegisteredGrads / totalNumberOfGrads * 100).toFixed(2)}% )
                     </Typography>
                     <Typography variant='h5'style={{marginTop: '0.5rem'}}>
                        Total number of grads with Bios: {totalNumberOfBios} ( {(totalNumberOfBios / totalNumberOfGrads * 100).toFixed(2)}% )
                     </Typography>
                     <Typography variant='h5'style={{marginTop: '0.5rem'}}>
                        % of Registered grads with Bios: {(totalNumberOfBios / totalNumberOfRegisteredGrads * 100).toFixed(2)}%
                     </Typography>
                  </Grid>

                  <Grid container item xs={12} sm={6} justify='flex-start'>
                     <Typography variant='h5'>
                        Number Registered Last 30 Days: {thirtyDayCount}
                     </Typography>
                     <Typography variant='h5'style={{marginTop: '0.5rem'}}>
                        Number Registered Last 60 Days: {sixtyDayCount}
                     </Typography>
                     <Typography variant='h5'style={{marginTop: '0.5rem'}}>
                        Number Registered Last 90 Days: {ninetyDayCount}
                     </Typography>
                     <Grid container item xs={12} sm={6} justify='flex-start'>

                        {show30Button ?
                           <Button variant="contained" color="primary" onClick={showThirty}>
                                 Show 30
                           </Button>
                           :
                           <Button variant="contained" color="primary" disabled>
                                 Show 30
                           </Button>
                        }

                        {show60Button ?
                           <Button variant="contained" color="primary" onClick={showSixty} style={{marginLeft: '0.25rem'}}>
                                 Show 60
                           </Button>
                           :
                           <Button variant="contained" color="primary" disabled style={{marginLeft: '0.25rem'}}>
                                 Show 60
                           </Button>
                        }

                        {show90Button ?
                           <Button variant="contained" color="primary" onClick={showNinety} style={{marginLeft: '0.25rem'}}>
                                 Show 90
                           </Button>
                           :
                           <Button variant="contained" color="primary" disabled style={{marginLeft: '0.25rem'}}>
                                 Show 90
                           </Button>
                        }

                     </Grid>
                  </Grid>

                  <Grid container item xs={12} sm={6} justify='flex-start' style={{marginTop: '1rem'}}>
                     <Typography variant='h5'>
                        Number of Users with More than One Failed Logins: {totalFailedLogins}
                     </Typography>
                  </Grid>

                  <Grid container item xs={12} sm={6} justify='flex-start' style={{marginTop: '1rem'}}>
                     <Typography variant='h5'>
                        Number of Users who Deleted their Data: {totalNumberOfDeletedGrads}
                     </Typography>
                  </Grid>

                  {setShowAnalyticBadRegisters ?
                     <AnalyticBadRegisters badUserInfo={badUserInfo} />
                     :
                     null
                  }

                  {!show30Names ?
                     <ShowNewRegisters regArray={thirtyDayArray} />
                     :
                     null
                  }

                  {!show60Names ?
                     <ShowNewRegisters regArray={sixtyDayArray} />
                     :
                     null
                  }

                  {!show90Names ?
                     <ShowNewRegisters regArray={ninetyDayArray} />
                     :
                     null
                  }

               </Grid>
            </Grid>
         </Container>
      </Box>
   )
}

export default Analytics