/*
 * This is the top <MySelect /> Component on the <Search /> Page.
 */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import MySelect from './MySelect'
import { phpFileNameAction } from "../Redux/Actions"
import PropTypes from 'prop-types'
import { search_by_city_state, search_for_current_companies, search_for_current_job_titles, search_for_schools_all, search_for_tps_classes } from '../Helpers'
import { store } from "../Redux/Store"

const SearchBoxOne = ({ getDataFromSearchBoxOne }) => {

    const options = ['Astronaut', 'City', 'Class', 'Commandant', 'Current Company', 'Current Job Title', 'Education', 'General Officer', 'Get All Grads', 'State']

    const [ searchByCityState, setSearchByCityState ] = useState('')

    // This takes city or state from the first dropdown, goes to the db and gets that data
    //  in order to populate the second dropdown where the user actually selects the city
    //  or state to search for other alumni
    const handleSearchByCityAndStateChange = (e) => {

        setSearchByCityState(e.target.value)

        if (e.target.value === 'City') {
            setSearchByCityState('city')
        }
        else if (e.target.value === 'State') {
            setSearchByCityState('state')
        }
        else if (e.target.value === "Class") {
            setSearchByCityState('class')
        }
        else if (e.target.value === "Current Job Title") {
            setSearchByCityState('currentJobTitle')
        }
        else if (e.target.value === "Current Company") {
            setSearchByCityState('currentCompany')
        }
        else if (e.target.value === 'Education') {
            setSearchByCityState('education')
            store.dispatch(phpFileNameAction('search_for_schools_all'))
        }
        else if (e.target.value === "Astronaut") {
            setSearchByCityState('astronaut')
            store.dispatch(phpFileNameAction('get_astronauts'))
        }
        else if (e.target.value === "Commandant") {
            setSearchByCityState('commandant')
            store.dispatch(phpFileNameAction('get_commandants'))
        }
        else if (e.target.value === "Get All Grads") {
            setSearchByCityState('getallgrads')
            store.dispatch(phpFileNameAction('getallgrads'))
        }
        else if (e.target.value === "General Officer") {
            setSearchByCityState('generalOfficer')
            store.dispatch(phpFileNameAction('get_general_officers'))
        }
        else { setSearchByCityState('error') }
    }

    useEffect( () => {
        var searchByCityStateData = new FormData()

        searchByCityStateData.append("searchByCityState", searchByCityState.toLowerCase())

        if (searchByCityState === "city" || searchByCityState === "state") {
            axios.post(search_by_city_state, searchByCityStateData)
                .then(res => res.data)
                .then(data => {
                    if (searchByCityState === 'city') {
                        getDataFromSearchBoxOne({type: 'city', data: Object.values(data.allCitiesAndStates)})
                    }
                    else if (searchByCityState === 'state') {
                        getDataFromSearchBoxOne({type: 'state', data: Object.values(data.allStates)})
                    }
                })
        }
        else if (searchByCityState === 'class') {
            axios.post(search_for_tps_classes, searchByCityStateData)
                .then(res => res.data)
                .then(data => {
                    getDataFromSearchBoxOne({type: 'class', data: Object.values(data.tpsClasses)})
                })
        }
        else if (searchByCityState === 'currentJobTitle') {
            axios.post(search_for_current_job_titles, searchByCityStateData)
                .then(res => res.data)
                .then(data => {
                    getDataFromSearchBoxOne({type: 'currentJobTitle', data: Object.values(data.currentJobTitles)})
                })
        }
        else if (searchByCityState === 'currentCompany') {
            axios.post(search_for_current_companies, searchByCityStateData)
                .then(res => res.data)
                .then(data => {
                    getDataFromSearchBoxOne({type: 'currentCompany', data: Object.values(data.currentCompanies)})
                })
        }
        else if (searchByCityState === 'education') {
            axios.post(search_for_schools_all, searchByCityStateData)
                .then( res => res.data )
                .then( data => {
                    /* data.educationAll is an array of objects like [{userId: 2368, educationBS: "", educationMS: "", educationPHD: ""}]
                       schoolArrayNeedsWord is a single array of schoold that strips out the userId, can have null or ""*/
                    const schoolArrayNeedsWork = data.educationAll.flatMap( school => {
                        return [school.educationBS, school.educationMS, school.educationPHD]
                    } )

                    /* This removes null and "" and then at the end sorts the array. Duplicate schools may be present. */
                    const schoolArrayWithDupes = schoolArrayNeedsWork.filter( school => {
                        if( school === null || school === "" ){ return null }
                        else { return school }
                    } ).sort()

                    /* Filters out duplicate schools */
                    const schoolArray = schoolArrayWithDupes.filter( (school, index, arr) => {
                        if( index < arr.length ){
                            if( school === arr[index + 1] ){ return null }
                            else { return school }
                        }
                        return null
                    } )

                    getDataFromSearchBoxOne({type: 'education', data: schoolArray})
                })

        }
        else if (searchByCityState === 'astronaut'){getDataFromSearchBoxOne({type: 'astronaut', data: []})}
        else if (searchByCityState === 'commandant'){getDataFromSearchBoxOne({type: 'commandant', data: []})}
        else if (searchByCityState === 'generalOfficer'){getDataFromSearchBoxOne({type: 'generalOfficer', data: []})}
        else if (searchByCityState === 'getallgrads'){getDataFromSearchBoxOne({type: 'getallgrads', data: []})}
    }, [getDataFromSearchBoxOne, searchByCityState] )

    return (
        <Grid container justify='center' item style={{ width: "70%" }} >
            <MySelect name="searchByCityState" label="Search Criteria" options={options} onChange={handleSearchByCityAndStateChange} />
        </Grid>
    )
}

SearchBoxOne.propTypes = {
    getDataFromSearchBoxOne: PropTypes.func
}

export default SearchBoxOne
