import React, { useCallback, useState } from 'react'
import Announcement from './Announcement'
import { Box, Divider, Paper, Typography } from '@material-ui/core'
import { createBrowserHistory } from 'history';
import GetAnnouncementsData from '../Data/GetAnnoucementsData'
import { Redirect } from "react-router-dom"

const history = createBrowserHistory();

const LandingPageAnnouncements = props => {

   const [ allAnnouncements, setAllAnnouncements ] = useState([])
   const [ announcementId, setAnnouncementId ] = useState(0)

  const getData = useCallback( (d) => {
      setAllAnnouncements(d.allAnnouncements)
  }, [] )

   const handleUpdateAfterDeleteAnnouncement = (id) => {
      const updatedAnnouncements = allAnnouncements.filter(announcement => announcement.id !== id)
      setAllAnnouncements(updatedAnnouncements)
   }

   const showOneAnnouncement = (id) => {
      history.push('https::/www.usaftpsalumni.com')
      setAnnouncementId(id)
   }

   const displayAnnouncements = allAnnouncements.map((announcement, i) => {
      const { announcementPara1, announcementSynopsis, id, title } = announcement
      // i is sent to inhibit the first announcement Divider, OTW there are two dividers under the Header
      return <Announcement key={id} announcementSynopsis={announcementSynopsis} i={i} id={id} title={title}
            announcementPara1={announcementPara1}
            updateAfterDeleteAnnouncement={handleUpdateAfterDeleteAnnouncement}
            showOneAnnouncement={showOneAnnouncement}
      />
   })

   return (
      <Paper style={{ padding: "0.25rem", marginTop: "1rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }} >
         <GetAnnouncementsData getData={getData}/>
         {announcementId > 0 ? <Redirect to="/ShowOneAnnouncement" /> : null}
         <Typography variant="h6" style={{ marginTop: "1rem" }} className="Landing-announcements-Header">
            Announcements
            </Typography>
         <Box style={{ width: "100%", paddingTop: "1rem" }}>
            <Divider light />
         </Box>
         <div className="Landing-announcements">
            {displayAnnouncements}
         </div>
      </Paper>
   )
}

export default LandingPageAnnouncements
