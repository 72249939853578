/*
 * This is the page a new grad is taken to after initial registration and first login
 */
import React, { useEffect, useState } from 'react'
import "./AddGrad.css"
import { addGrad, get_single_user, update_new_user } from '../Helpers'
import axios from "axios"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import GradCard from "../Components/GradCard"
import Grid from "@material-ui/core/Grid"
import MySelect from "../Components/MySelect"
import { Redirect } from "react-router-dom"
import { showAlumniBioNavbarButtonAction, showClassPagesNavbarButtonAction, showHistoryNavbarButtonAction,
         showHomeNavbarButtonAction, showMenuNavbarButtonAction, userIdAction } from "../Redux/Actions"
import Snackbar from "../Atoms/Snackbar"
import { store } from "../Redux/Store"
import TextField from "@material-ui/core/TextField"
import { milRankOptions, milStatusOptions, trackOptions } from "../config"

const AddGrad = () => {
    
    const { admin, alumni, isActive, logout, signedInUserId, superUser } = store.getState()

    const [ address, setAddress ] = useState('')
   //  const [ astronaut, setAstronaut ] = useState(false)
    const [ callsign, setCallsign ] = useState('')
   //  const [ cDot, setCDot ] = useState(false)
    const [ city, setCity ] = useState('')
   //  const [ commandant, setCommandant ] = useState(false)
    const [ country, setCountry ] = useState('')
    const [ currentCompany, setCurrentCompany ] = useState('')
    const [ currentJobTitle, setCurrentJobTitle ] = useState('')
   //  const [ distinguishedAlumnus, setDistinguishedAlumnus ] = useState(false)
    const [ educationBS, setEducationBS ] = useState('')
    const [ educationMS, setEducationMS ] = useState('')
    const [ educationPHD, setEducationPHD ] = useState('')
   //  const [ generalOfficer, setGeneralOfficer ] = useState(false)
    const [ givenFirstName, setGivenFirstName ] = useState('')
    const [ gradCardOpen, setGradCardOpen ] = useState(false)
    const [ gradNumber, setGradNumber ] = useState('')
    const [ lastName, setLastName ] = useState('')
   //  const [ liethenTittle, setLiethenTittle ] = useState(false)
    const [ militaryService, setMilitaryService ] = useState('')
    const [ userStatus, setUserStatus ] = useState('')
    const [ newUserIsAdmin, setNewUserIsAdmin ] = useState(false)
    const [ newUserIsAlumni, setNewUserIsAlumni ] = useState(false)
    const [ newUserIsSuperUser, setNewUserIsSuperUser ] = useState(false)
    const [ password, setPassword ] = useState('')
    const [ password1, setPassword1 ] = useState('')
    const [ personalCell, setPersonalCell ] = useState('')
    const [ personalEmail, setPersonalEmail ] = useState('')
    const [ preferredFirstName, setPreferredFirstName ] = useState('')
   //  const [ propWash, setPropWash ] = useState(false)
    const [ militaryRank, setMilitaryRank ] = useState('')
   //  const [ raymondJones, setRaymondJones ] = useState(false)
    const [ showOneGrad, setShowOneGrad ] = useState(false)
    const [ snackBarOpen, setSnackBarOpen ] = useState(false)
    const [ state, setState ] = useState('')
    const [ suffix, setSuffix ] = useState('')
    const [ tpsClass, setTpsClass ] = useState('')
    const [ track, setTrack ] = useState('')
    const [ turnNavbarButtonsBackOn, setTurnNavbarButtonsBackOn ] = useState(false)
    const [ username, setUsername ] = useState('')
    const [ workEmail, setWorkEmail ] = useState('')
    const [ workPhone, setWorkPhone ] = useState('')
    const [ zip, setZip ] = useState('')

    const handleAddressChange = (e) => { setAddress(e.target.value) }
   //  const handleAstronautChange = () => { setAstronaut(!astronaut) }
    const handleCallsignChange = (e) => { setCallsign(e.target.value) }
   //  const handleCDotChange = () => { setCDot(!cDot) }
    const handleCityChange = (e) => { setCity(e.target.value) }
   //  const handleCommandantChange = () => { setCommandant(!commandant) }
    const handleCountryChange = (e) => { setCountry(e.target.value) }
    const handleCurrentCompanyChange = (e) => { setCurrentCompany(e.target.value) }
    const handleCurrentJobTitleChange = (e) => { setCurrentJobTitle(e.target.value) }
   //  const handleDistinguishedAlumnusChange = () => { setDistinguishedAlumnus(!distinguishedAlumnus) }

    const handleEducationBSChange = (e) => { setEducationBS(e.target.value) }
    const handleEducationMSChange = (e) => { setEducationMS(e.target.value) }
    const handleEducationPHDChange = (e) => { setEducationPHD(e.target.value) }

   //  const handleGeneralOfficerChange = () => { setGeneralOfficer(!generalOfficer) }
    const handleGivenFirstNameChange = (e) => { setGivenFirstName(e.target.value) }
    const handleLastNameChange = (e) => { setLastName(e.target.value) }
   //  const handleLiethenTittleChange = () => { setLiethenTittle(!liethenTittle) }
    const handleMilitaryServiceChange = (e) => { setMilitaryService(e.target.value) }
    const handleUserStatusChange = (e) => { setUserStatus(e.target.value) }
    const handleNewUserIsAdminChange = () => { setNewUserIsAdmin(!newUserIsAdmin) }
    const handleNewUserIsAlumniChange = () => { setNewUserIsAlumni(!newUserIsAlumni) }
    const handleNewUserIsSuperUserChange = () => { setNewUserIsSuperUser(!newUserIsSuperUser) }
    const handlePasswordChange = (e) => { setPassword(e.target.value) }
    const handlePassword1Change = (e) => { setPassword1(e.target.value) }
    const handlePersonalCellChange = (e) => { setPersonalCell(e.target.value) }
    const handlePersonalEmailChange = (e) => { setPersonalEmail(e.target.value) }
    const handlePreferredFirstNameChange = (e) => { setPreferredFirstName(e.target.value) }
   //  const handlePropWashChange = () => { setPropWash(!propWash) }
    const handleMilitaryRankChange = (e) => { setMilitaryRank(e.target.value) }
   //  const handleRaymondJonesChange = () => { setRaymondJones(!raymondJones) }
    const handleStateChange = (e) => { setState(e.target.value) }
    const handleSuffixChange = (e) => { setSuffix(e.target.value) }
    const handleTrackChange = (e) => { setTrack(e.target.value) }
    const handleUsernameChange = (e) => { setUsername(e.target.value) }
    const handleWorkEmailChange = (e) => { setWorkEmail(e.target.value) }
    const handleWorkPhoneChange = (e) => { setWorkPhone(e.target.value) }
    const handleZipChange = (e) => { setZip(e.target.value) }

    /* This populates the gradNumber and tpsClass (parsed from username) fields from the db */
    useEffect( () => {
        
        let getGradNumberFormData = new FormData()
        getGradNumberFormData.append('signedInUserId', signedInUserId)

        axios.post(get_single_user, getGradNumberFormData)
            .then( res => res.data )
            .then( data => {
                setGradNumber(data.gradNumber)

                /* Parse TPS Class */
                let tempTpsClass = data.username.split('.')
                if (tempTpsClass.length === 3) {
                    setTpsClass(tempTpsClass[2])
                }
                return 1
            })
        
        // These turn off <Navbar /> and <Hamburger /> buttons so the user
        // can't leave the page via those buttons
        store.dispatch(showAlumniBioNavbarButtonAction(false))
        store.dispatch(showClassPagesNavbarButtonAction(false))
        store.dispatch(showHistoryNavbarButtonAction(false))
        store.dispatch(showHomeNavbarButtonAction(false))
        store.dispatch(showMenuNavbarButtonAction(false))
    }, [signedInUserId] )

    /* If user logs out this makes the navbar buttons active again */
    useEffect( () => {
        if(logout || turnNavbarButtonsBackOn){
            store.dispatch(showAlumniBioNavbarButtonAction(true))
            store.dispatch(showClassPagesNavbarButtonAction(true))
            store.dispatch(showHistoryNavbarButtonAction(true))
            store.dispatch(showHomeNavbarButtonAction(true))
        }
    }, [logout, turnNavbarButtonsBackOn] )

    const handleSubmit = (e) => {
        e.preventDefault()

        var addGradFormData = new FormData()

        addGradFormData.append("address", address)
        addGradFormData.append("admin", admin)
        addGradFormData.append("alumni", alumni)
      //   addGradFormData.append("astronaut", astronaut)
        addGradFormData.append("callsign", callsign)
      //   addGradFormData.append("cDot", cDot)
        addGradFormData.append("city", city)
      //   addGradFormData.append("commandant", commandant)

        if(country === 'US'){ addGradFormData.append("country", "USA") }
        else if(country === 'us'){ addGradFormData.append("country", "USA") }
        else if(country === 'usa'){ addGradFormData.append("country", "USA") }
        else{ addGradFormData.append("country", country) }
        
        addGradFormData.append("currentCompany", currentCompany)
        addGradFormData.append("currentJobTitle", currentJobTitle)
      //   addGradFormData.append("distinguishedAlumnus", distinguishedAlumnus)

        addGradFormData.append("educationBS", educationBS)
        addGradFormData.append("educationMS", educationMS)
        addGradFormData.append("educationPHD", educationPHD)

      //   addGradFormData.append("generalOfficer", generalOfficer)
        addGradFormData.append("givenFirstName", givenFirstName)
        addGradFormData.append("gradNumber", gradNumber)
        addGradFormData.append("isActive", isActive)
        addGradFormData.append("lastName", lastName)
      //   addGradFormData.append("liethenTittle", liethenTittle)
        addGradFormData.append("militaryService", militaryService)
        addGradFormData.append("newUserIsAdmin", newUserIsAdmin)
        addGradFormData.append("newUserIsAlumni", newUserIsAlumni)
        addGradFormData.append("newUserIsSuperUser", newUserIsSuperUser)
        addGradFormData.append("password", password)
        addGradFormData.append("personalCell", personalCell)
        addGradFormData.append("personalEmail", personalEmail)
        addGradFormData.append("preferredFirstName", preferredFirstName)
      //   addGradFormData.append("propWash", propWash)
        addGradFormData.append("militaryRank", militaryRank)
      //   addGradFormData.append("raymondJones", raymondJones)
        addGradFormData.append("signedInUserId", signedInUserId)
        addGradFormData.append("state", state)
        addGradFormData.append("suffix", suffix)
        addGradFormData.append("superUser", superUser)
        addGradFormData.append("track", track)
        addGradFormData.append("tpsClass", tpsClass)
        addGradFormData.append("username", username)
        addGradFormData.append("userStatus", userStatus)
        addGradFormData.append("workEmail", workEmail)
        addGradFormData.append("workPhone", workPhone)
        addGradFormData.append("zip", zip)

        // This section is for admin or superUser entry of a new user
        if ( admin || superUser ) {
            if(password === password1){
                axios.post(addGrad, addGradFormData)
                .then(res => res.data)
                .then(data => {
                    if (data.msg === "Good Insert") {
                        setGradCardOpen(true)
                        setTimeout(() => {
                            setGradCardOpen(false)
                        }, 5000)
                        store.dispatch(userIdAction(data.userId))
                        setShowOneGrad(true)
                    }
                    else if (data.msg === "Bad Insert") {
                        setSnackBarOpen(true)
                        setTimeout(() => {
                            setSnackBarOpen(false)
                        }, 3000)
                    }
                })
            } else { alert("AddGrad Component passwords do NOT match") }
        }
        // This section is for a newly active grad to enter their initial data
        else {
            axios.post(update_new_user, addGradFormData)
            .then(res => {
                store.dispatch(userIdAction(res.data.signedInUserId))
                return res.data
            })
            .then(data => {
                if (data.msg === "Good Insert") {
                    setShowOneGrad(true)
                    store.dispatch(showMenuNavbarButtonAction(true))
                }
                else if (data.msg === "Bad Insert") {
                    setSnackBarOpen(true)
                    setTimeout(() => {
                        setSnackBarOpen(false)
                    }, 3000)
                }
            })
            .catch(error => {
                console.log('AddGrad .catch error is', error)
            })
        }
        setTurnNavbarButtonsBackOn(true)
    }

    return (
        <Box style={{marginTop: '4rem'}}>
            {showOneGrad ? <Redirect to="/ShowOneGrad" /> : null}
            <Grid container justify="center">
                <h1>Alumni Information</h1>
                {
                    gradCardOpen ?
                        <GradCard
                            givenFirstName={givenFirstName}
                            lastName={lastName}
                            militaryRank={militaryRank}
                            track={track}
                            tpsClass={tpsClass}
                        />
                        :
                        null
                }
                <form className="AddGrad-form">

                    {/* Displays only for a superUser */}
                    {superUser ?
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsSuperUser" label="Super User" options={["Yes", "No"]} onChange={handleNewUserIsSuperUserChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsAdmin" label="Admin" options={["Yes", "No"]} onChange={handleNewUserIsAdminChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsAlumni" label="Alumni" options={["Yes", "No"]} onChange={handleNewUserIsAlumniChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                    }

                    {/* Displays only for an admin */}
                    {admin ?
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsAdmin" label="Admin" options={["Yes", "No"]} onChange={handleNewUserIsAdminChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsAlumni" label="Alumni" options={["Yes", "No"]} onChange={handleNewUserIsAlumniChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                    }

                    {superUser || admin ?
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Username" name="username" onChange={handleUsernameChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Password" name="password" type="password" onChange={handlePasswordChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Re-enter Password" name="password1" type="password" onChange={handlePassword1Change} />
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                    }

                    {/* Alumni see what is below, but not what is above */}
                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField autoFocus={true} className="AddGrad-text-field" label="Callsign" name="callsign" onChange={handleCallsignChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="TPS Class (ex. 2002a)" name="tpsClass" value={tpsClass} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Grad Number" name="gradNumber" value={gradNumber} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    <Grid item md={12}>
                        <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Given First Name - required" name="givenFirstName" required onChange={handleGivenFirstNameChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Preferred First Name" name="preferredFirstName" onChange={handlePreferredFirstNameChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Last Name - required" name="lastName" required onChange={handleLastNameChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Suffix" name="suffix" onChange={handleSuffixChange} />
                            </Grid>
                        </Grid>
                    </Grid>

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Address" name="address" onChange={handleAddressChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="City" name="city" onChange={handleCityChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="State (2 digits)" name="state" onChange={handleStateChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Zip" name="zip" onChange={handleZipChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Personal Cell" name="personalCell" onChange={handlePersonalCellChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Personal E-mail" name="personalEmail" onChange={handlePersonalEmailChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Work Phone" name="workPhone" onChange={handleWorkPhoneChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Work E-mail" name="workEmail" onChange={handleWorkEmailChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Current Job Title" name="currentJobTitle" onChange={handleCurrentJobTitleChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Current Company" name="currentCompany" onChange={handleCurrentCompanyChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Country" name="country" onChange={handleCountryChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Military Service" name="militaryService" onChange={handleMilitaryServiceChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="School Name - Education BS" name="educationBS" onChange={handleEducationBSChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="School Name - Education MS" name="educationMS" onChange={handleEducationMSChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="School Name - Education PhD" name="educationPHD" onChange={handleEducationPHDChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="militaryRank" label="Rank" options={milRankOptions} onChange={handleMilitaryRankChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="track" label="Student Track" options={trackOptions} onChange={handleTrackChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="userStatus" label="Military Status" options={milStatusOptions} onChange={handleUserStatusChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {/* {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="cDot" label="C-Dot" options={["Yes", "No"]} onChange={handleCDotChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="liethenTittle" label="Liethen-Tittle" options={["Yes", "No"]} onChange={handleLiethenTittleChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="propWash" label="Prop Wash" options={["Yes", "No"]} onChange={handlePropWashChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="raymondJones" label="Raymond Jones" options={["Yes", "No"]} onChange={handleRaymondJonesChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="distinguishedAlumnus" label="Disting'd Alum" options={["Yes", "No"]} onChange={handleDistinguishedAlumnusChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    } */}

                    {/* {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="commandant" label="Commandant" options={["Yes", "No"]} onChange={handleCommandantChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="astronaut" label="Astronaut" options={["Yes", "No"]} onChange={handleAstronautChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="generalOfficer" label="General Officer" options={["Yes", "No"]} onChange={handleGeneralOfficerChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    } */}

                    <Grid item md={12} style={{ marginLeft: 8 * 6, marginBottom: 8 * 2 }}>
                        <Grid container>
                            {snackBarOpen ?
                                <Snackbar type="error" msg="User not inserted" />
                                : null
                            }
                            {givenFirstName.length > 0 &&
                                lastName.length > 0
                                ?
                                <Button variant="contained" color="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            :
                                <Button variant="contained" color="primary" disabled onClick={handleSubmit}>
                                    Submit
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Box>
    )
}
export default AddGrad